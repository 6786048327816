import './App.css';
import routes from './routes';
import Home from './view/Home';
import Login from './view/Login';
import Order from './view/Order';
import Users from './view/Users';
import Reviews from './view/Reviews';
import Gallery from './view/Gallery';
import Founders from './view/Founders';
import SubAdmin from './view/SubAdmin';
import NotFound from './view/NotFound';
import Partners from './view/Partners';
import ScrollToTop from './ScrollToTop';
import Layout from './component/Layout';
import ContactUs from './view/ContactUs';
import ViewEvent from './view/ViewEvent';
import EditEvent from './view/EditEvent';
import Questions from './view/Questions';
import AddReview from './view/AddReview';
import { useSelector } from 'react-redux';
import EventStats from './view/StatsView';
import ViewReview from './view/ViewReview';
import DashboardView from './view/Dashboard';
import HeaderImage from './view/HeaderImage';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import CreateSubAdmin from './view/CreateAdmin';
import AddNewPartner from './view/AddNewPartner';
import EventTemplate from './view/EventTemplate';
import AddNewFounder from './view/AddNewFounder';
import CreateNewEvent from './view/CreateNewEvent';
import AddNewQuestion from './view/AddNewQuestion';
import AddGalleryImage from './view/AddGalleryImage';
import { Routes, Route, Navigate } from "react-router";

function App() {
  const { admin } = useSelector((state) => state.auth);

  return (
    <>
      <ScrollToTop />
      <Routes>
        {!admin && <Route element={<Login />} path={routes.login} />}
        <Route path="/" element={admin ? <Layout /> : <Navigate to={routes.login} replace />}>
          <Route element={<Home />} path={routes.home} />
          <Route element={<Gallery />} path={routes.gallery} />
          <Route element={<Users />} path={routes.user_list} />
          <Route element={<Order />} path={routes.order_list} />
          <Route element={<ViewEvent />} path={routes.event_view} />
          <Route element={<SubAdmin />} path={routes.sub_admin_list} />
          <Route element={<EditEvent />} path={routes.event_edit} />
          <Route element={<EventTemplate />} path={routes.event_template} />
          <Route element={<DashboardView />} path={routes.dashboard} />
          <Route element={<EventStats />} path={routes.event_stats} />
          <Route element={<CreateSubAdmin />} path={routes.create_new_admin} />
          <Route element={<ContactUs />} path={routes.contact_us_list} />
          <Route element={<AddGalleryImage />} path={routes.add_gallery_img} />
          <Route element={<CreateNewEvent />} path={routes.create_new_event} />
          <Route element={<Questions />} path={routes.question_answer} />
          <Route element={<AddNewQuestion />} path={routes.add_new_question} />
          <Route element={<HeaderImage />} path={routes.header_image} />
          <Route element={<Reviews />} path={routes.reviews} />
          <Route element={<AddReview />} path={routes.add_new_review} />
          <Route element={<ViewReview />} path={routes.view_review} />
          <Route element={<Partners />} path={routes.partners} />
          <Route element={<AddNewPartner />} path={routes.add_new_partner} />
          <Route element={<Founders />} path={routes.founder_list} />
          <Route element={<AddNewFounder />} path={routes.add_new_founder} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;
