import commonServices from './common.services';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
    headerImage: null
};

export const getHeaderImage = createAsyncThunk('common/getHeaderImage', async(params, thunkAPI) => {
    try{
        return await commonServices.getHeaderImage();
    }
    catch(error){
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const updateHeaderImage = createAsyncThunk('common/updateHeaderImage', async(params, thunkAPI) => {
    try{
        return await commonServices.updateHeaderImage(params);
    }
    catch(error){
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getHeaderImage.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getHeaderImage.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(getHeaderImage.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.message = action.payload;
                state.headerImage = action.payload.data[0];
            })
            .addCase(updateHeaderImage.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateHeaderImage.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(updateHeaderImage.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.message = action.payload;
            })
    }
})

export default commonSlice.reducer;