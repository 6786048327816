import axios from "axios";
import routes from "../routes";

const getHeaderImage = async() => {
    const res = await axios.get(routes.GET_HEADER_IMAGE, {
        headers: { Authorization: localStorage.getItem("token") },
    });
    return res.data;
}

const updateHeaderImage = async(data) => {
    const res = await axios.post(routes.ADD_HEADER_IMAGE, data, {
        headers: { Authorization: localStorage.getItem("token") },
    });
    return res.data;
}

const commonServices = {
    getHeaderImage,
    updateHeaderImage
}

export default commonServices;