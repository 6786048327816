import { useState } from "react";
import { toast } from "react-toastify";
import Loader from '../assets/loader.gif';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { getQuestions, addQuestion } from "../redux/questions/question.slice";

let formData = {
    question: "",
    answer: ""
}

const QuestionForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [submitData, setSubmitData] = useState(formData);

    const { isLoading } = useSelector((state) => state.question);

    const handleSubmit = () => {
        const status = formValidator();
        if(status.isValid){
            const res = dispatch(addQuestion(submitData));
            res?.then((r) => {
                if(r?.payload?.statusCode === 200){
                    getQuestions();
                    setSubmitData({...formData});
                    toast.success(r?.payload?.message);
                    navigate(-1);
                    return;
                }
                toast.error(r?.payload);
            })
            return;
        }
        toast.error(status.message);
    }

    const formValidator = () => {
        const status = {isValid: true, message: ""};
        if(submitData.question === "" || !submitData.question){
            status.isValid = false;
            status.message = "Question required";
            return status;
        }
        if(submitData.answer === "" || !submitData.answer){
            status.isValid = false;
            status.message = "Answer required";
            return status;
        }
        return status;
    }

    const handleChange = (e) => {
        e.target.name === "question" && setSubmitData({...submitData, question: e.target.value});
        e.target.name === "answer" && setSubmitData({...submitData, answer: e.target.value});
    }

    return (
        <div className="event-form-container">
            <div className="mt-80 w-50">
                <h4 className="text-center">
                    Add New Question And Answer
                </h4>
                <div>
                    <div className="mb-3 form-box">
                        <label className="form-label">
                            Question
                        </label>
                        <textarea
                            type="text"
                            name="question"
                            onChange={handleChange}
                            className="form-control"
                            value={submitData.question}
                            placeholder="Enter question"
                        ></textarea>
                    </div>
                    <div className="mb-3 form-box">
                        <label className="form-label">
                            Answer
                        </label>
                        <textarea
                            type="text" 
                            name="answer"
                            onChange={handleChange}
                            className="form-control"
                            value={submitData.answer}
                            placeholder="Enter answer"
                        ></textarea>
                    </div>
                    <button
                        type="button"
                        disabled={isLoading}
                        onClick={handleSubmit}
                        className={`btn primary-button-2 ${isLoading && 'btn-disabled'}`}
                    >
                        {isLoading ? <img src={Loader} alt="Loader" /> : "Submit"}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default QuestionForm;