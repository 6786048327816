
import { useSelector } from "react-redux";
import PartnerList from "../component/PartnerList";
import AccessDenied from "../component/AccessDenied";
import { isTaskAllowed } from "../utils/isTaskAllowed";

const Partners = () => {
    const { admin } = useSelector((state) => state.auth);

    if(admin?.email !== "admin@thriftyx.com" &&
        !isTaskAllowed("Partners", admin?.assignedWork)) return (<AccessDenied />)

    return ( <PartnerList /> )
}
export default Partners;