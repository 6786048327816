import Table from "./Table";
import routes from '../routes';
import PageLoader from './PageLoader';
import debounce from "lodash.debounce";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import { FaSearch } from "react-icons/fa";
import { CgDanger } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState, useCallback } from "react";
import { getFounders, deleteFounder } from "../redux/founder/founder.slice";

const heading = ["SNo.", "Date", "Name", "Designation", "Description", "Image", "Action"];

const useStyles = makeStyles({
    searchInput: {
        marginBottom: 16,
    },
    searchIcon: {
        right: 16,
        top: 4
    },
    arertHeading: {
        textAlign: 'center',
        fontSize: 20,
        fontWeight: 'bold'
    },
    alertDetail: {
        fontSize: 17,
        textAlign: 'center',
    }
});

const FounderList = () => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [itemId, setItemId] = useState("");
    const [searchText, setSearchText] = useState("");
    const [isModalDelete, setModalDelete] = useState(false);

    const { isLoading, totalCount, founderData } = useSelector((state) => state.founder);

    useEffect(() => {
        dispatch(getFounders({limit, offset, searchText}));
    },[limit, offset, searchText, dispatch]);

    const debouncedSave = useMemo(() => 
        debounce((value) => setSearchText(value), 1000), 
        [setSearchText]
    );

    const handleSearchText = useCallback((event) => {
        if(limit === 10 && offset === 0){
            debouncedSave(event.target.value);
        }
        else{
            setLimit(10);
            setOffset(0);
        }
    }, [limit, offset, debouncedSave]);

    const nextClicked = (l, o) => {
        setLimit(l+10);
        setOffset(o+10);
    }

    const prevClicked = (l, o) => {
        setLimit(l-10);
        setOffset(o-10);
    }

    const deleteItemById = (id) => {
        setItemId(id);
        setModalDelete(true);
    }

    const confirmClicked = () => {
        const res = dispatch(deleteFounder(itemId));
        res?.then((r) => {
            if(r?.payload.statusCode === 200){
                toast.success("Founder Deleted Successfully");
                dispatch(getFounders({limit, offset, searchText}));
                return;
            }
            toast.error(r?.payload?.message);
        })
    }

    if(isLoading) return ( <PageLoader />)

    return (
        <>
            <div className="container mt-80">
                <div className="position-relative">
                    <div className={`${classes.searchIcon} position-absolute`}>
                        <FaSearch />
                    </div>
                    <input
                        type="text"
                        onChange={handleSearchText}
                        placeholder="Search founder"
                        className={`${classes.searchInput} form-control`}
                    />
                </div>
                <Table
                    limit={limit}
                    offset={offset}
                    btnText="Add New"
                    heading={heading}
                    isBtnCreate={true}
                    data={founderData}
                    tableFor="founder"
                    totalCount={totalCount}
                    btnRoute={routes.add_new_founder}
                    deleteItem={(id) => deleteItemById(id)}
                    next={(limit, offset) => nextClicked(limit, offset)}
                    previous={(limit, offset) => prevClicked(limit, offset)}
                />
            </div>
            <div
                id="modal"
                className="modal fade"
                data-bs-keyboard="false"
            >
                <div className="modal-dialog modal-dialog-centered">
                    {isModalDelete &&
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5 fw-bold text-danger">
                                    Confirm
                                </h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body user-detail">
                                <div className="text-center">
                                    <CgDanger fontSize={48} className="text-danger" />
                                </div>
                                <p className={`${classes.arertHeading} text-danger`}>
                                    Are you sure ?
                                </p>
                                <p className={classes.alertDetail}>
                                    You want to delete the founder.
                                </p>
                            </div>
                            <div className="modal-footer">
                                <button
                                    data-bs-dismiss="modal"
                                    className="btn btn-secondary">
                                    Cancel
                                </button>
                                <button
                                    data-bs-dismiss="modal"
                                    onClick={confirmClicked}
                                    className="btn btn-danger">
                                    Proceed
                                </button>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default FounderList;