import { useEffect } from "react";
import ReviewForm from "../component/ReviewForm";
import PageLoader from '../component/PageLoader';
import { useDispatch, useSelector } from "react-redux";
import { getReviewById } from "../redux/review/review.silce";

const ViewReview = () => { 
    const dispatch = useDispatch();

    const { isLoading, reviewId } = useSelector((state) => state.review);

    useEffect(() => { 
        reviewId && localStorage.setItem('reviewId', reviewId);
        const id = reviewId ? reviewId : localStorage.getItem('reviewId');
        dispatch(getReviewById({id}));
    },[reviewId, dispatch]);

    if(isLoading) return ( <PageLoader /> );

    return (<ReviewForm />)
}
export default ViewReview;