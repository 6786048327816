import questionServices from './question.services';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
    totalCount: 0,
    questionById: null,
    questionData: null,
};

export const addQuestion = createAsyncThunk('question/addQuestion', async(params, thunkAPI) => {
    try{
        return await questionServices.addQuestion(params);
    }
    catch(error){
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const getQuestions = createAsyncThunk('question/getQuestions', async(params, thunkAPI) => {
    try{
        return await questionServices.getQuestions(params);
    }
    catch(error){
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const getQuestionById = createAsyncThunk('question/getQuestionById', async(params, thunkAPI) => {
    try{
        return await questionServices.getQuestionById(params);
    }
    catch(error){
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const deleteQuestion = createAsyncThunk('question/deleteQuestion', async(params, thunkAPI) => {
    try{
        return await questionServices.deleteQuestion(params);
    }
    catch(error){
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

const questionSlice = createSlice({
    name: 'question',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(addQuestion.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(addQuestion.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(addQuestion.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.message = action.payload;
            })
            .addCase(getQuestions.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getQuestions.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(getQuestions.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.message = action.payload;
                state.questionData = action.payload.data.result;
                state.totalCount = action.payload.data.totalCount;
            })
            .addCase(getQuestionById.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getQuestionById.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(getQuestionById.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.message = action.payload;
                state.questionById = action.payload.data;
            })
            .addCase(deleteQuestion.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteQuestion.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(deleteQuestion.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.message = action.payload;
            })
    }
});
export default questionSlice.reducer;