import { toast } from "react-toastify";
import { RxCross2 } from "react-icons/rx";
import Loader from '../assets/loader.gif';
import { useState, useEffect } from "react";
import uploadOnS3 from '../redux/uploadOnS3';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from "react-router-dom";
import { addReview, getReviews } from "../redux/review/review.silce";

let formData = {
    name: "",
    rating: 0,
    image: "",
    location: "",
    description: "",
    comments: []
}

const QuestionForm = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [commentValue, setCommentValue] = useState("");
    const [imageLoader, setImageLoader] = useState(false);
    const [submitData, setSubmitData] = useState(formData);

    const { isLoading, reviewById } = useSelector((state) => state.review);

    useEffect(() => {
        if(location.pathname.split("/")[1] === "view"){
            reviewById && setSubmitData({...reviewById});
        }
    }, [reviewById, location.pathname]);

    const handleSubmit = () => {
        const status = formValidator();
        if(status.isValid){
            const res = dispatch(addReview(submitData));
            res?.then((r) => {
                if(r?.payload?.statusCode === 200){
                    getReviews();
                    setSubmitData({...formData});
                    toast.success(r?.payload?.message);
                    navigate(-1);
                    return;
                }
                toast.error(r?.payload);
            })
            return;
        }
        toast.error(status.message);
    }

    const formValidator = () => {
        const status = {isValid: true, message: ""};
        if(submitData.name === "" || !submitData.name){
            status.isValid = false;
            status.message = "Name required";
            return status;
        }
        if(submitData.location === "" || !submitData.location){
            status.isValid = false;
            status.message = "Address required";
            return status;
        }
        if(submitData.rating === "" || !submitData.rating){
            status.isValid = false;
            status.message = "Rating required";
            return status;
        }
        if(isNaN(submitData.rating)){
            status.isValid = false;
            status.message = "Invalid rating entered";
            return status;
        }
        if(submitData.rating < 1 || submitData.rating > 5){
            status.isValid = false;
            status.message = "Rating value must be between 1 to 5";
            return status;
        }
        if(submitData.description === "" || !submitData.description){
            status.isValid = false;
            status.message = "Review required";
            return status;
        }
        if(submitData.comments && submitData.comments.length === 0){
            status.isValid = false;
            status.message = "Add atleast 1 comment";
            return status;
        }
        if(submitData.image === "" || !submitData.image){
            status.isValid = false;
            status.message = "Image required";
            return status;
        }
        return status;
    }

    const handleChange = (e) => {
        e.target.name === "name" && setSubmitData({...submitData, name: e.target.value});
        e.target.name === "address" && setSubmitData({...submitData, location: e.target.value});
        e.target.name === "rating" && setSubmitData({...submitData, rating: e.target.value});
        e.target.name === "description" && setSubmitData({...submitData, description: e.target.value});
    }

    const handleImageChange = async (e) => {
        if(e.target.name === "file"){
            if(e.target.files[0].type === "image/jpeg" || 
                e.target.files[0].type === "image/svg+xml" ||
                e.target.files[0].type === "image/png"){
                if(e.target.files[0].size > 1049000){
                    toast.error("Image size must be less than 1MB");
                    return;
                }
                else{
                    // console.log("==>file===",e.target.files[0]);
                    // const reader = new FileReader();
                    // reader.onload = function (e) {
                    //     console.log("==>file===reader");
                    //     const img = new Image();
                    //     img.onload = function () {
                    //         console.log("Width:", img.width);
                    //         console.log("Height:", img.height);
                    //     };
                    //     img.src = e.target.result
                    // };
                    // console.log("==>file===",e.target.files[0]);
                    // console.log("==>file===",e.target.result);
                    // return;
                    setImageLoader(true);
                    const res = await uploadOnS3(e.target.files[0]);
                    if(res?.statusCode === 200){
                        setImageLoader(false);
                        setSubmitData({...submitData, image: res?.data?.urls[0]});
                    }else{
                        setImageLoader(false);
                        toast.error("Error while uploading file");
                    }
                }
                return;
            }
            toast.error("Invalid file type");
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            let array = [...submitData.comments];
            const index = array.indexOf(commentValue);
            if(index !== -1) return toast.error("Comment already exists"); 
            array.push(commentValue);
            setCommentValue("");
            setSubmitData({...submitData, comments: [...array]});
        }
    };

    const removeComment = (index) => {
        let array = [...submitData.comments];
        array.splice(index, 1)
        setSubmitData({...submitData, comments: [...array]});
    }

    const handleCommentChange = (e) => setCommentValue(e.target.value);

    return (
        <div className="event-form-container">
            <div className="mt-80 w-50">
                <h4 className="text-center">
                    Add New Review
                </h4>
                <div>
                    <div className="mb-3 form-box">
                        <label className="form-label">
                            Name
                        </label>
                        <input
                            type="text"
                            name="name"
                            value={submitData.name}
                            onChange={handleChange}
                            className="form-control"
                            placeholder="Enter name"
                        />
                    </div>
                    <div className="mb-3 form-box">
                        <label className="form-label">
                            Address
                        </label>
                        <input
                            type="text"
                            name="address"
                            onChange={handleChange}
                            className="form-control"
                            value={submitData.location}
                            placeholder="Enter address"
                        />
                    </div>
                    <div className="mb-3 form-box">
                        <label className="form-label">
                            Rating
                        </label>
                        <input
                            name="rating"
                            type="number"
                            onChange={handleChange}
                            className="form-control"
                            value={submitData.rating}
                            placeholder="Enter rating"
                        />
                    </div>
                    <div className="mb-3 form-box">
                        <label className="form-label">
                            Review
                        </label>
                        <textarea
                            type="text" 
                            name="description"
                            onChange={handleChange}
                            className="form-control"
                            value={submitData.description}
                            placeholder="Enter your review"
                        ></textarea>
                    </div>
                    <div className="mb-3 form-box">
                        <label className="form-label">
                            Add Comments
                            <span className="option-text">
                                ( Press enter to add comment )
                            </span>
                        </label>
                        <input
                            type="text"
                            name="comment"
                            value={commentValue}
                            className="form-control"
                            onKeyDown={handleKeyDown}
                            placeholder="Enter comment"
                            onChange={handleCommentChange}
                        />
                        {submitData.comments && submitData.comments.length > 0 &&
                            <div>
                                {submitData.comments.map((item, index) => (
                                    <div key={item} className="comment-box">
                                        <div>
                                            <p>{item}</p>
                                            <button
                                                type="button"
                                                onClick={() => removeComment(index)}
                                            >
                                                <RxCross2 />
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        }
                    </div>
                    <div className="mb-3 form-box">
                        {submitData?.image &&
                            <img
                                alt="HeaderImage"
                                className="header-image"
                                src={submitData.image}
                            />
                        }
                        {location.pathname.split("/")[1] !== "view" &&
                            <>
                                <label className="form-label">
                                    Image
                                </label>
                                <input
                                    id="file"
                                    type="file"
                                    name="file"
                                    className="form-control"
                                    onChange={handleImageChange}
                                />
                            </>
                        }
                        {imageLoader &&
                            <div className="d-flex justify-content-center w-24">
                                <img
                                    width={24}
                                    className="image-loader"
                                    src={Loader} alt="Loader"
                                />
                            </div>
                        }
                    </div>
                    {location.pathname.split("/")[1] !== "view" &&
                        <button
                            type="button"
                            disabled={isLoading}
                            onClick={handleSubmit}
                            className={`btn primary-button-2 ${isLoading && 'btn-disabled'}`}
                        >
                            {isLoading ? <img src={Loader} alt="Loader" /> : "Submit"}
                        </button>
                    }
                </div>
            </div>
        </div>
    )
}

export default QuestionForm;