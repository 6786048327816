import authSlice from './auth/auth.slice';
import userSlice from './user/user.slice';
import eventSlice from './event/event.slice';
import reviewSlice from './review/review.silce';
import commonSlice from './common/common.slice';
import { configureStore } from '@reduxjs/toolkit';
import founderSlice from './founder/founder.slice';
import partnerSlice from './partner/partner.slice';
import questionSlice from './questions/question.slice';

const store = configureStore({
    reducer: {
        user: userSlice,
        auth: authSlice,
        event: eventSlice,
        common: commonSlice,
        review: reviewSlice,
        founder: founderSlice,
        partner: partnerSlice,
        question: questionSlice
    }
});

export default store;