import PageLoader from './PageLoader';
import { toast } from 'react-toastify';
import Loader from '../assets/loader.gif';
import { useEffect, useState } from 'react';
import uploadOnS3 from '../redux/uploadOnS3';
import { useDispatch, useSelector } from 'react-redux';
import { getHeaderImage, updateHeaderImage } from '../redux/common/common.slice';

const HeaderImage = () => {
    const dispatch = useDispatch();

    const [imageUrl, setImageUrl] = useState("");
    const [imageLoader, setImageLoader] = useState(false);
    const [isChangeImage, setIsChangeImage] = useState(false);

    const { isLoading, headerImage } = useSelector((state) => state.common);

    useEffect(() => { dispatch(getHeaderImage()) }, [dispatch]);

    const handleChange = async(e) => {
        if(e.target.name === "file"){
            if(e.target.files[0]?.type === "image/jpeg" || 
                e.target.files[0]?.type === "image/svg+xml" ||
                e.target.files[0]?.type === "image/png"){
                if(e.target.files[0].size > 1049000){
                    toast.error("Image size must be less than 1MB");
                    return;
                }
                else{
                    setImageLoader(true);
                    const res = await uploadOnS3(e.target.files[0]);
                    if(res?.statusCode === 200){
                        setImageLoader(false);
                        setImageUrl(res?.data?.urls[0]);
                    }else{
                        setImageLoader(false);
                        toast.error("Error while uploading file");
                    }
                }
                return;
            }
            toast.error("Invalid file type");
        }
    }

    const handleSubmit = () => {
        if(!imageUrl || imageUrl === ""){
            toast.error("Upload image");
            return;
        }
        const body = {
            image: imageUrl,
            id: "675ebf6d4490a895e86725bc"
        }
        const res = dispatch(updateHeaderImage(body));
        res?.then((r) => {
            if(r?.payload?.statusCode === 200){
                toast.success("Image Changed Successfully");
                return;
            }
            toast.error(r?.payload?.message || r?.payload)
        })
    }

    if(isLoading) return ( <PageLoader />)
        
    return (
        <div className="event-form-container">
            <div className="mt-80 w-50">
                <h4 className="text-center fw-bold">
                    Current Header Image
                </h4>
                <div className="header-image-container">
                    <img
                        alt="HeaderImage"
                        className="header-image"
                        src={imageUrl ? imageUrl : headerImage?.image}
                    />
                    {isChangeImage ? 
                        <div className="upload-image-form">
                            <div className="mb-3 form-box">
                                <input
                                    id="file"
                                    type="file"
                                    name="file"
                                    onChange={handleChange}
                                    className="form-control"
                                />
                                {imageLoader &&
                                    <div className="d-flex justify-content-center w-24">
                                        <img
                                            width={24}
                                            className="image-loader"
                                            src={Loader} alt="Loader"
                                        />
                                    </div>
                                }
                            </div>
                            <button
                                type="button"
                                disabled={isLoading}
                                onClick={handleSubmit}
                                className={`btn primary-button-2 ${isLoading && 'btn-disabled'}`}
                            >
                                {isLoading ? <img src={Loader} alt="Loader" /> : "Update"}
                            </button>
                        </div>
                        :
                        <div className="change-image">
                            <button
                                type="button"
                                onClick={() => setIsChangeImage(true)}
                                className="btn btn-outline-primary primary-button"
                            >
                                Change Image
                            </button>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
export default HeaderImage;