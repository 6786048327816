import axios from "axios";
import routes from "../routes";

const addReview = async(data) => {
    const res = await axios.post(routes.AD_REVIEW, data, {
        headers: { Authorization: localStorage.getItem("token") },
    });
    return res.data;
}

const getReviews = async(params) => {
    const { limit, offset, searchText } = params;
    const res = await axios.get(routes.REVIEW, {
        params: { limit, offset, searchText },
        headers: { Authorization: localStorage.getItem("token") }
    });
    return res.data;
}

const getReviewById = async(params) => {
    const { id } = params;
    const res = await axios.get(routes.REVIEW+"/"+id, {
        headers: { Authorization: localStorage.getItem("token") }
    });
    return res.data;
}

const deleteReview = async(id) => {
    const res = await axios.delete(routes.AD_REVIEW+"/"+id, {
        headers: { Authorization: localStorage.getItem("token") }
    });
    return res.data;
}

const reviewServices = { 
    addReview,
    getReviews,
    deleteReview,
    getReviewById
}

export default reviewServices;