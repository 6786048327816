import { toast } from "react-toastify";
import Loader from '../assets/loader.gif';
import { useState, useEffect } from "react";
import uploadOnS3 from '../redux/uploadOnS3';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from "react-router-dom";
import { addFounder, getFounders } from "../redux/founder/founder.slice";

let formData = {
    name: "",
    image: "",
    designation: "",
    description: ""
}

const FounderForm = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [imageLoader, setImageLoader] = useState(false);
    const [submitData, setSubmitData] = useState(formData);

    const { isLoading, reviewById } = useSelector((state) => state.review);

    useEffect(() => {
        if(location.pathname.split("/")[1] === "view"){
            reviewById && setSubmitData({...reviewById});
        }
    }, [reviewById, location.pathname]);

    const handleSubmit = () => {
        const status = formValidator();
        if(status.isValid){
            const res = dispatch(addFounder(submitData));
            res?.then((r) => {
                if(r?.payload?.statusCode === 200){
                    getFounders();
                    setSubmitData({...formData});
                    toast.success(r?.payload?.message);
                    navigate(-1);
                    return;
                }
                toast.error(r?.payload);
            })
            return;
        }
        toast.error(status.message);
    }

    const formValidator = () => {
        const status = {isValid: true, message: ""};
        if(submitData.name === "" || !submitData.name){
            status.isValid = false;
            status.message = "Name required";
            return status;
        }
        if(submitData.designation === "" || !submitData.designation){
            status.isValid = false;
            status.message = "Designation required";
            return status;
        }
        if(submitData.description === "" || !submitData.description){
            status.isValid = false;
            status.message = "Description required";
            return status;
        }
        if(submitData.image === "" || !submitData.image){
            status.isValid = false;
            status.message = "Image required";
            return status;
        }
        return status;
    }

    const handleChange = (e) => {
        e.target.name === "name" && setSubmitData({...submitData, name: e.target.value});
        e.target.name === "designation" && setSubmitData({...submitData, designation: e.target.value});
        e.target.name === "description" && setSubmitData({...submitData, description: e.target.value});
    }

    const handleImageChange = async (e) => {
        if(e.target.name === "file"){
            if(e.target.files[0].type === "image/jpeg" || 
                e.target.files[0].type === "image/svg+xml" ||
                e.target.files[0].type === "image/png"){
                if(e.target.files[0].size > 1049000){
                    toast.error("Image size must be less than 1MB");
                    return;
                }
                else{
                    setImageLoader(true);
                    const res = await uploadOnS3(e.target.files[0]);
                    if(res?.statusCode === 200){
                        setImageLoader(false);
                        setSubmitData({...submitData, image: res?.data?.urls[0]});
                    }else{
                        setImageLoader(false);
                        toast.error("Error while uploading file");
                    }
                }
                return;
            }
            toast.error("Invalid file type");
        }
    }

    return (
        <div className="event-form-container">
            <div className="mt-80 w-50">
                <h4 className="text-center">
                    Add New Founder
                </h4>
                <div>
                    <div className="mb-3 form-box">
                        <label className="form-label">
                            Name
                        </label>
                        <input
                            type="text"
                            name="name"
                            value={submitData.name}
                            onChange={handleChange}
                            className="form-control"
                            placeholder="Enter name"
                        />
                    </div>
                    <div className="mb-3 form-box">
                        <label className="form-label">
                            Designation
                        </label>
                        <input
                            type="text"
                            name="designation"
                            onChange={handleChange}
                            className="form-control"
                            value={submitData.designation}
                            placeholder="Enter designation"
                        />
                    </div>
                    <div className="mb-3 form-box">
                        <label className="form-label">
                            Description
                        </label>
                        <textarea
                            type="text" 
                            name="description"
                            onChange={handleChange}
                            className="form-control"
                            value={submitData.description}
                            placeholder="Enter description"
                        ></textarea>
                    </div>
                    <div className="mb-3 form-box">
                        {submitData?.image &&
                            <img
                                alt="HeaderImage"
                                className="header-image"
                                src={submitData.image}
                            />
                        }
                        {location.pathname.split("/")[1] !== "view" &&
                            <>
                                <label className="form-label">
                                    Image
                                </label>
                                <input
                                    id="file"
                                    type="file"
                                    name="file"
                                    className="form-control"
                                    onChange={handleImageChange}
                                />
                            </>
                        }
                        {imageLoader &&
                            <div className="d-flex justify-content-center w-24">
                                <img
                                    width={24}
                                    className="image-loader"
                                    src={Loader} alt="Loader"
                                />
                            </div>
                        }
                    </div>
                    {location.pathname.split("/")[1] !== "view" &&
                        <button
                            type="button"
                            disabled={isLoading}
                            onClick={handleSubmit}
                            className={`btn primary-button-2 ${isLoading && 'btn-disabled'}`}
                        >
                            {isLoading ? <img src={Loader} alt="Loader" /> : "Submit"}
                        </button>
                    }
                </div>
            </div>
        </div>
    )
}

export default FounderForm;