import Moment from "moment";
import PieChart from "./PieChart";
import PageLoader from './PageLoader';
import { toast } from "react-toastify";
import { FaUsers } from "react-icons/fa";
import { makeStyles } from "@mui/styles";
import { FaSearch } from "react-icons/fa";
import DatePicker from "react-datepicker";
import { useEffect, useState } from "react";
import { FaRupeeSign } from "react-icons/fa";
import { MdAttachMoney } from "react-icons/md";
import { FaCartShopping } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { getOrders, getUsers, getOrderTickets } from "../redux/user/user.slice";

const useStyles = makeStyles({
    searchInput: {
        marginBottom: 16,
    },
    searchIcon: {
        right: 0,
        top: 0,
        padding: "7px 36px",
        border: "none",
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
        backgroundColor: "rgb(108,48,237)"
    }
})

const orderChartData = {
    labels: ["Completed", "In Progress", "Failed"],
    datasets: [
        {
            label: "Total Count",
            data: [10, 15, 20],
            backgroundColor: [
                "rgba(25, 135, 84, 1)",
                "rgba(255, 193, 7, 1)",
                "rgba(220, 53, 69, 1)"
            ],
            hoverOffset: 4
        }
    ]
}

const Dashboard = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [minDate, setMinDate] = useState(null);
    const [maxDate, setMaxDate] = useState(null);
    const [totalOrders, setTotalOrders] = useState(0);
    const [failedPrice, setFailedPrice] = useState(0);
    const [verifiedUser, setVerifiedUser] = useState(0);
    const [unVerifiedUser, setUnVerifiedUser] = useState(0);
    const [progressPrice, setProgressPrice] = useState(0);
    const [completedPrice, setCompletedPrice] = useState(0);
    const [ticketName, setTicketName] = useState(null);
    const [searchValue, setSearchValue] = useState("");
    const [minDateForSearch, setMinDateForSearch] = useState(null);
    const [maxDateForSearch, setMaxDateForSearch] = useState(null);
    const [orderChart, setOrderChart] = useState(orderChartData);

    const { 
        isLoading, 
        orderDataTicket,
        orderTotalCount } = useSelector((state) => state.user);

    useEffect(() => {
        const res = dispatch(getUsers({limit: 0, offset: 0}));
        res?.then((r) => {
            if(r?.payload?.statusCode === 200){
                setVerifiedUser(r?.payload?.data?.result.filter((item) => {return item?.isVerified}));
                setUnVerifiedUser(r?.payload?.data?.result.filter((item) => {return !item?.isVerified}));
            }
        })
    },[dispatch]);

    useEffect(() => {
        let completed = [];
        let inProgress = [];
        let failed = [];
        const res = dispatch(getOrders({ 
            limit: 0, 
            offset: 0, 
            minDate: minDateForSearch,
            maxDate: maxDateForSearch
        }));
        res?.then((r) => {
            if(r?.payload?.statusCode === 200){
                completed = r?.payload?.data?.result.filter((item) => {return item?.status === "completed"});
                inProgress = r?.payload?.data?.result.filter((item) => {return item?.status === "in_progress"});
                failed = r?.payload?.data?.result.filter((item) => {return item?.status === "failed"});
                
                const sum1 = r?.payload?.data?.result?.reduce((acc, item) => {
                    if (item?.status === "completed") {
                      return acc + item?.grand_total;
                    }
                    return acc;
                }, 0);

                const sum2 = r?.payload?.data?.result?.reduce((acc, item) => {
                    if (item?.status === "in_progress") {
                      return acc + item?.grand_total;
                    }
                    return acc;
                }, 0);

                const sum3 = r?.payload?.data?.result?.reduce((acc, item) => {
                    if (item?.status === "failed") {
                      return acc + item?.grand_total;
                    }
                    return acc;
                }, 0);
                setCompletedPrice(sum1);
                setProgressPrice(sum2);
                setFailedPrice(sum3);
                setTotalOrders(r?.payload?.data?.totalCount);
                let newData = [completed?.length, inProgress?.length, failed?.length];
                setOrderChart(prevState => ({
                    ...prevState,
                    datasets: [
                      {
                        ...prevState.datasets[0],
                        data: newData
                      }
                    ]
                }));
            }
        });
    },[dispatch, minDateForSearch, maxDateForSearch]);

    useEffect(() => {
        dispatch(getOrderTickets({
            limit,
            offset,
            searchText: ticketName,
            minDate: minDateForSearch,
            maxDate: maxDateForSearch
        }));
    },[dispatch, limit, offset, ticketName, minDateForSearch, maxDateForSearch])

    const searchOrders = () => {
        let miDate = minDate ? new Date(minDate) : new Date();
        let maDate = maxDate ? new Date(maxDate) : new Date();
        if(miDate > maDate){
            toast.error("Invalid Date Range Selected");
            return;
        }
        setLimit(10);
        setOffset(0);
        setMinDateForSearch(miDate);
        setMaxDateForSearch(maDate);
    }

    const pageChange = (type) => {
        switch(type){
            case "next" : 
                setLimit(limit+10);
                setOffset(offset+10);
                break;
            case "prev" :
                setLimit(limit-10);
                setOffset(offset-10);
                break;
            default :
                break;

        }
    }

    const searchOrderByTicket = () => setTicketName(searchValue);
    const handleSearchText = e => setSearchValue(e.target.value);
    const handleMinDateSelect = date => setMinDate(date.toISOString())
    const handleMaxDateSelect = date => setMaxDate(date.toISOString())

    if(isLoading) return ( <PageLoader />)

    return (
        <>
            <div className="container mt-80">
                <div className="row total-box">
                    <div className="col-md-3 total-item border-primary">
                        <div>
                            <h4 className="text-primary">Total Orders</h4>
                            <p>{totalOrders}</p>
                        </div>
                        <FaCartShopping fontSize={32} />
                    </div>
                    <div className="col-md-3 total-item border-danger">
                        <div>
                            <h4 className="text-danger">Total Earnings</h4>
                            <p className="total-earning">
                                <FaRupeeSign fontSize={16} /> {completedPrice}
                            </p>
                        </div>
                        <MdAttachMoney fontSize={32} />
                    </div>
                    <div className="col-md-3 total-item border-warning">
                        <div>
                            <h4 className="text-warning">Total Users</h4>
                            <div className="d-flex align-items-center">
                                <p className="small-circle bg-success"></p>
                                <p>{verifiedUser?.length}</p>
                                <p className="small-circle bg-danger ml-12"></p>
                                <p>{unVerifiedUser?.length}</p>
                            </div>
                        </div>
                        <FaUsers fontSize={32} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="order-panel border-primary">
                            <h4 className="text-primary">
                                Order Panel
                            </h4>
                            <div className="panel-aria">
                                <div className="p-aria-1">
                                    <div className="d-flex align-items-start mt-2">
                                        <div className="mr-6">
                                            <label className="form-label fw-bold">
                                                Select Min Date
                                            </label>
                                            <div className="form-box date">
                                                <DatePicker
                                                    showIcon
                                                    maxDate={new Date()}
                                                    className="form-control"
                                                    selected={minDate ? minDate : new Date()}
                                                    onSelect={handleMinDateSelect}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <label className="form-label fw-bold">
                                                Select Max Date
                                            </label>
                                            <div className="form-box date">
                                                <DatePicker
                                                    showIcon
                                                    maxDate={new Date()}
                                                    className="form-control"
                                                    selected={maxDate ? maxDate : new Date()}
                                                    onSelect={handleMaxDateSelect}
                                                />
                                            </div>
                                        </div>
                                        <div className="btn-search-order d-flex">
                                            <button 
                                                type="button"
                                                onClick={searchOrders}
                                            >
                                                <FaSearch color="#fff" />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="table-responsive order-table table-mt">
                                        <table className="table table-borderless">
                                            <thead className="table-dark">
                                                <tr>
                                                    <th>
                                                        Status
                                                    </th>
                                                    <th>
                                                        Order Count
                                                    </th>
                                                    <th>
                                                        Order (%)
                                                    </th>
                                                    <th>
                                                        Revenue
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="tr-repeat">
                                                    <td className="text-primary">
                                                        Completed
                                                    </td>
                                                    <td>
                                                        {orderChart?.datasets[0]?.data[0]}
                                                    </td>
                                                    <td>
                                                        {isNaN(parseInt((orderChart?.datasets[0]?.data[0]/totalOrders)*100)) ? 0 :
                                                        parseInt((orderChart?.datasets[0]?.data[0]/totalOrders)*100)}%
                                                    </td>
                                                    <td>
                                                        <FaRupeeSign size="12" />
                                                        <span>
                                                            {completedPrice}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr className="tr-repeat">
                                                    <td className="text-primary">
                                                        In Progress
                                                    </td>
                                                    <td>
                                                        {orderChart?.datasets[0]?.data[1]}
                                                    </td>
                                                    <td>
                                                        {isNaN(parseInt((orderChart?.datasets[0]?.data[1]/totalOrders)*100)) ? 0 :
                                                        parseInt((orderChart?.datasets[0]?.data[1]/totalOrders)*100)}%
                                                    </td>
                                                    <td>
                                                        <FaRupeeSign size="12" />
                                                        <span>
                                                            {progressPrice}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr className="tr-repeat">
                                                    <td className="text-primary">
                                                        Failed
                                                    </td>
                                                    <td>
                                                        {orderChart?.datasets[0]?.data[2]}
                                                    </td>
                                                    <td>
                                                        {isNaN(parseInt((orderChart?.datasets[0]?.data[2]/totalOrders)*100)) ? 0 :
                                                        parseInt((orderChart?.datasets[0]?.data[2]/totalOrders)*100)}%
                                                    </td>
                                                    <td>
                                                        <FaRupeeSign size="12" />
                                                        <span>
                                                            {failedPrice}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="p-aria-2">
                                    <PieChart data={orderChart} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="order-panel border-primary">
                            <div className="position-relative">
                                <button
                                    onClick={searchOrderByTicket}
                                    className={`${classes.searchIcon} position-absolute`}
                                >
                                    <FaSearch color={"#fff"} />
                                </button>
                                <input
                                    type="text"
                                    value={searchValue}
                                    onChange={handleSearchText}
                                    className={`${classes.searchInput} form-control`} 
                                    placeholder="Search order" 
                                />
                            </div>
                            <div className="table-responsive order-table table-mt">
                                <table className="table table-borderless">
                                    <thead className="table-dark">
                                        <tr>
                                            <th>
                                                Order Id
                                            </th>
                                            <th>
                                                Buyer Info
                                            </th>
                                            <th>
                                                Ticket Name
                                            </th>
                                            <th>
                                                Purchased On
                                            </th>
                                            <th>
                                                Qty
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="ticket-content">
                                        {orderDataTicket?.map((item, index) => (
                                            item?.tickets?.length > 0 && 
                                            item?.tickets?.map((it, i) => (
                                                <tr key={`${index}${i}`}>
                                                    <td>{item?.order_id?.split("_")[1]}</td>
                                                    <td>
                                                        <b>{item?.user_id?.name}</b><br/>
                                                        {item?.user_id?.email}<br/>
                                                        {item?.user_id?.phone}
                                                    </td>
                                                    <td>{it?.name}</td>
                                                    <td>{Moment(item?.updatedBy).format("MMM DD YYYY")}</td>
                                                    <td>{it?.count}</td>
                                                </tr>
                                            ))
                                        ))}
                                    </tbody>
                                </table>
                                {orderTotalCount > 10 &&
                                    <nav aria-label="Page navigation example">
                                        <ul className="pagination justify-content-between">
                                            <li className={`page-item ${offset === 0 && 'disabled'}`}>
                                                <button
                                                    className="page-link"
                                                    aria-disabled="true"
                                                    onClick={() => pageChange("prev")}>
                                                    Previous
                                                </button>
                                            </li>
                                            <li className={`page-item ${orderDataTicket?.length < 10 && 'disabled'}`}>
                                                <button
                                                    className="page-link"
                                                    onClick={() => pageChange("next")}>
                                                    Next
                                                </button>
                                            </li>
                                        </ul>
                                    </nav>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Dashboard;