import { useState } from "react";
import { toast } from "react-toastify";
import Loader from '../assets/loader.gif';
import uploadOnS3 from '../redux/uploadOnS3';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { getPartners, addPartner } from "../redux/partner/partner.slice";

let formData = {
    name: "",
    logo: ""
}

const PartnerForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [imageLoader, setImageLoader] = useState(false);
    const [submitData, setSubmitData] = useState(formData);

    const { isLoading } = useSelector((state) => state.partner);

    const handleSubmit = () => {
        const status = formValidator();
        if(status.isValid){
            const res = dispatch(addPartner(submitData));
            res?.then((r) => {
                if(r?.payload?.statusCode === 200){
                    getPartners();
                    setSubmitData({...formData});
                    toast.success(r?.payload?.message);
                    navigate(-1);
                    return;
                }
                toast.error(r?.payload);
            })
            return;
        }
        toast.error(status.message);
    }

    const formValidator = () => {
        const status = {isValid: true, message: ""};
        if(submitData.name === "" || !submitData.name){
            status.isValid = false;
            status.message = "Partner name required";
            return status;
        }
        if(submitData.logo === "" || !submitData.logo){
            status.isValid = false;
            status.message = "Partner logo required";
            return status;
        }
        return status;
    }

    const handleImageChange = async (e) => {
        if(e.target.name === "file"){
            if(e.target.files[0].type === "image/jpeg" || 
                e.target.files[0].type === "image/svg+xml" ||
                e.target.files[0].type === "image/png"){
                if(e.target.files[0].size > 1049000){
                    toast.error("Image size must be less than 1MB");
                    return;
                }
                else{
                    setImageLoader(true);
                    const res = await uploadOnS3(e.target.files[0]);
                    if(res?.statusCode === 200){
                        setImageLoader(false);
                        setSubmitData({...submitData, logo: res?.data?.urls[0]});
                    }else{
                        setImageLoader(false);
                        toast.error("Error while uploading file");
                    }
                }
                return;
            }
            toast.error("Invalid file type");
        }
    }

    const handleChange = (e) => setSubmitData({...submitData, name: e.target.value});

    return (
        <div className="event-form-container">
            <div className="mt-80 w-50">
                <h4 className="text-center">
                    Add New Partner
                </h4>
                <div>
                    <div className="mb-3 form-box">
                        <label className="form-label">
                            Name
                        </label>
                        <input
                            type="text"
                            name="name"
                            value={submitData.name}
                            onChange={handleChange}
                            className="form-control"
                            placeholder="Enter name"
                        />
                    </div>
                    <div className="mb-3 form-box">
                        {submitData?.logo &&
                            <img
                                alt="HeaderImage"
                                src={submitData.logo}
                                className="header-image"
                            />
                        }
                        <label className="form-label">
                            Image
                        </label>
                        <input
                            id="file"
                            type="file"
                            name="file"
                            className="form-control"
                            onChange={handleImageChange}
                        />
                        {imageLoader &&
                            <div className="d-flex justify-content-center w-24">
                                <img
                                    width={24}
                                    className="image-loader"
                                    src={Loader} alt="Loader"
                                />
                            </div>
                        }
                    </div>
                    <button
                        type="button"
                        disabled={isLoading}
                        onClick={handleSubmit}
                        className={`btn primary-button-2 ${isLoading && 'btn-disabled'}`}
                    >
                        {isLoading ? <img src={Loader} alt="Loader" /> : "Submit"}
                    </button>
                </div>
            </div>
        </div>
    )
}
export default PartnerForm;