import { useSelector } from "react-redux";
import AccessDenied from "../component/AccessDenied";
import { isTaskAllowed } from "../utils/isTaskAllowed";
import ContactUsList from "../component/ContactUsList";

const ContactUs = () => {
    const { admin } = useSelector((state) => state.auth);

    if(admin?.email !== "admin@thriftyx.com" &&
        !isTaskAllowed("Contact Us", admin?.assignedWork)) return (<AccessDenied />)

    return (<ContactUsList />)
}
export default ContactUs;