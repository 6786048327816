import axios from "axios";
import routes from "../routes";

const addPartner = async(data) => {
    const res = await axios.post(routes.AD_PARTNER, data, {
        headers: { Authorization: localStorage.getItem("token") },
    });
    return res.data;
}

const getPartners = async(params) => {
    const { limit, offset, searchText } = params;
    const res = await axios.get(routes.PARTNER, {
        params: { limit, offset, searchText },
        headers: { Authorization: localStorage.getItem("token") }
    });
    return res.data;
}

const getPartnerById = async(params) => {
    const { id } = params;
    const res = await axios.get(routes.REVIEW+"/"+id, {
        headers: { Authorization: localStorage.getItem("token") }
    });
    return res.data;
}

const deletePartner = async(id) => {
    const res = await axios.delete(routes.AD_PARTNER+"/"+id, {
        headers: { Authorization: localStorage.getItem("token") }
    });
    return res.data;
}

const partnerServices = { 
    addPartner,
    getPartners,
    deletePartner,
    getPartnerById
}

export default partnerServices;