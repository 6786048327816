const routes = Object.freeze({
    home: "/",
    login: "/login",
    gallery: "/gallery",
    reviews: "/reviews",
    partners: "/partners",
    dashboard: "/dashboard",
    user_list: "/user-list",
    founder_list: "/founders",
    order_list: "/order-list",
    sub_admin_list: "/sub-admin",
    header_image: '/header-image',
    event_template: '/event/template',
    add_new_review: "/add-new-review",
    add_new_founder: "/add-new-founder",
    add_new_partner: "/add-new-partner",
    question_answer: "/question-answer",
    contact_us_list: "/contact-us-list",
    add_new_question: "/add-new-question",
    add_gallery_img: "/add-gallery-image",
    event_view: '/view/event/:event-name',
    event_edit: '/edit/event/:event-name',
    create_new_admin: '/create-new-admin',
    create_new_event: "/create-new-event",
    event_stats: "/stats/event/:event-name",
    view_review: "/view/review/:review-name",
});
export default routes;