import axios from "axios";
import routes from "../routes";

const addFounder = async(data) => {
    const res = await axios.post(routes.AD_FOUNDER, data, {
        headers: { Authorization: localStorage.getItem("token") },
    });
    return res.data;
}

const getFounders = async(params) => {
    const { limit, offset, searchText } = params;
    const res = await axios.get(routes.FOUNDER, {
        params: { limit, offset, searchText },
        headers: { Authorization: localStorage.getItem("token") }
    });
    return res.data;
}

const getFounderById = async(params) => {
    const { id } = params;
    const res = await axios.get(routes.REVIEW+"/"+id, {
        headers: { Authorization: localStorage.getItem("token") }
    });
    return res.data;
}

const deleteFounder = async(id) => {
    const res = await axios.delete(routes.AD_FOUNDER+"/"+id, {
        headers: { Authorization: localStorage.getItem("token") }
    });
    return res.data;
}

const partnerServices = { 
    addFounder,
    getFounders,
    deleteFounder,
    getFounderById
}

export default partnerServices;