import { useSelector } from "react-redux";
import ReviewList from "../component/ReviewList";
import AccessDenied from "../component/AccessDenied";
import { isTaskAllowed } from "../utils/isTaskAllowed";

const Reviews = () => {
    const { admin } = useSelector((state) => state.auth);

    if(admin?.email !== "admin@thriftyx.com" &&
        !isTaskAllowed("Reviews", admin?.assignedWork)) return (<AccessDenied />)

    return ( <ReviewList /> )
}
export default Reviews;