import routes from '../routes';
import { useState } from 'react';
import 'react-clock/dist/Clock.css';
import PageLoader from './PageLoader';
import { toast } from "react-toastify";
import Loader from '../assets/loader.gif';
import { RxCross2 } from "react-icons/rx";
import uploadOnS3 from '../redux/uploadOnS3';
import 'react-time-picker/dist/TimePicker.css';
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from 'react-redux';
import { addGalleryImage } from '../redux/event/event.slice';

// const formData = {
//     title:"",
//     category: "",
//     image:"",
// }

const GalleryForm = ({from}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { isLoader, galleryData } = useSelector((state) => state.event);

    const [galleryNewData, setGalleryData] = useState([]);
    const [imageLoader, setImageLoader] = useState(false);

    const handleChange = async(e) => {
        // e.target.name === "title" && setGalleryData({...galleryData, title: e.target.value});
        // e.target.name === "category" && setGalleryData({...galleryData, category: e.target.value});
        if(e.target.name === "file"){
            if(e.target.files[0].type === "image/jpeg" || 
                e.target.files[0].type === "image/svg+xml" ||
                e.target.files[0].type === "image/png"){
                if(e.target.files[0].size > 1049000){
                    toast.error("Image size must be less than 1MB");
                    return;
                }
                else{
                    setImageLoader(true);
                    let res = await uploadOnS3(e.target.files[0]);
                    if(res?.statusCode === 200){
                        setImageLoader(false);
                        setGalleryData([...galleryNewData, res?.data?.urls[0]]);
                    }else{
                        setImageLoader(false);
                        toast.error("Error while uploading file");
                    }
                }
                return;
            }
            toast.error("Invalid file type");
        }
    }

    const handleSubmit = () => {
        const body = {
            id: "673defde02008af3b8e5ec62",
            image: [...galleryData[0].image, ...galleryNewData]
        }
        const res = dispatch(addGalleryImage(body));
        res?.then((r) => {
            if(r?.payload?.statusCode === 200){
                // setGalleryData({...formData});
                toast.success(r?.payload?.message);
                navigate(routes.gallery);
                return;
            }
            toast.error(r?.payload);
        });
    }

    // const handleSubmit = () => {
    //     if(formValidator().isValid){
    //         if(from === "edit"){
    //             // let body = {
    //             //     name: eventData.name,
    //             //     type: eventData.type,
    //             //     city: eventData.city,
    //             //     venue: eventData.venue,
    //             //     image: eventData.image,
    //             //     date: eventData.date,
    //             //     time: eventData.time,
    //             //     age_limit: eventData.age_limit,
    //             //     description: eventData.description,
    //             //     address: eventData.address,
    //             //     tickets: eventData.tickets,
    //             //     status: eventData.status,
    //             // }
    //             // const res = dispatch(updateEvent({body, id: eventData._id}));
    //             // res?.then((r) => {
    //             //     if(r?.payload?.statusCode === 200){
    //             //         toast.success(r?.payload?.message);
    //             //         return;
    //             //     }
    //             //     toast.error(r?.payload);
    //             // });
    //             // return;
    //         }
    //         else{
    //             const body = {
    //                 id: "673defde02008af3b8e5ec62",
    //                 image: updatedArray
    //             }
    //             const res = dispatch(addGalleryImage(galleryData));
    //             res?.then((r) => {
    //                 if(r?.payload?.statusCode === 200){
    //                     setGalleryData({...formData});
    //                     toast.success(r?.payload?.message);
    //                     return;
    //                 }
    //                 toast.error(r?.payload);
    //             });
    //             return;
    //         }
    //     }
    //     toast.error(formValidator().message);
    // }

    // const formValidator = () => {
    //     let status = {isValid: true, message: ""};
    //     if(galleryData.title === "" || !galleryData.title){
    //         status.isValid = false;
    //         status.message = "Please enter image title";
    //         return status;
    //     }
    //     if(galleryData.category === "" || !galleryData.category){
    //         status.isValid = false;
    //         status.message = "Please enter image category";
    //         return status;
    //     }
    //     if(galleryData.image === "" || !galleryData.image){
    //         status.isValid = false;
    //         status.message = "Please upload image";
    //         return status;
    //     }
    //     return status;
    // }

    const removeImage = (url) => {
        const updatedArray = galleryNewData.filter(item => item !== url);
        setGalleryData([...updatedArray]);
    }

    if(isLoader) return ( <PageLoader />)

    return (
        <div className="event-form-container">
            <div className="mt-80 w-50">
                <h4 className="text-center fw-bold">
                    Add Gallery Image
                </h4>
                <div>
                    {/* <div className="mb-3 form-box">
                        <label htmlFor="name" className="form-label">
                            Title
                        </label>
                        <input
                            type="text" 
                            id="title"
                            name="title"
                            value={galleryData.title}
                            onChange={handleChange}
                            className="form-control"
                            placeholder="Enter event name" />
                    </div> */}
                    {/* <div className="mb-3 form-box">
                        <label htmlFor="name" className="form-label">
                            Category
                        </label>
                        <input
                            type="text" 
                            id="category"
                            name="category"
                            value={galleryData.category}
                            onChange={handleChange}
                            className="form-control"
                            placeholder="Enter event name" />
                    </div> */}
                    <div className="mb-3 form-box">
                        <label htmlFor="address" className="form-label">
                            Image
                        </label>
                        <input
                            id="file"
                            type="file"
                            name="file"
                            onChange={handleChange}
                            className="form-control"
                        />
                    </div>
                    {/* {(!from || from === "edit") &&
                        <button
                            type="button"
                            disabled={isLoader}
                            onClick={handleSubmit}
                            className={`btn primary-button-2 ${isLoader && 'btn-disabled'}`}>
                            {isLoader ? <img src={Loader} alt="Loader" /> : (from === "edit" ? "Save Changes" : "Submit")}
                        </button>
                    } */}
                    <button
                        type="button"
                        disabled={isLoader}
                        onClick={handleSubmit}
                        className={`btn primary-button-2 ${isLoader && 'btn-disabled'}`}
                    >
                        {isLoader ? <img src={Loader} alt="Loader" /> : "Submit"}
                    </button>
                    {imageLoader &&
                        <div className="d-flex justify-content-center img-loader">
                            <img src={Loader} alt="Loader" />
                        </div>
                    }
                    {galleryNewData.length > 0 && 
                        <div className="event-image new-size">
                            {galleryNewData?.map((item, index) =>
                                <div key={index} className="d-inline-block position-relative me-2 mb-2">
                                    <img
                                        src={item}
                                        alt="gallery_image"
                                    />
                                    <button
                                        type="button"
                                        className="btn-cross"
                                        onClick={() => removeImage(item)}
                                    > 
                                        <RxCross2 />
                                    </button>
                                </div>
                            )}
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default GalleryForm;