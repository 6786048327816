import founderServices from './founder.services';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
    totalCount: 0,
    founderById: null,
    founderData: null,
};

export const addFounder = createAsyncThunk('founder/addFounder', async(params, thunkAPI) => {
    try{
        return await founderServices.addFounder(params);
    }
    catch(error){
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const getFounders = createAsyncThunk('founder/getFounders', async(params, thunkAPI) => {
    try{
        return await founderServices.getFounders(params);
    }
    catch(error){
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const getFounderById = createAsyncThunk('founder/getFounderById', async(params, thunkAPI) => {
    try{
        return await founderServices.getFounderById(params);
    }
    catch(error){
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const deleteFounder = createAsyncThunk('founder/deleteFounder', async(params, thunkAPI) => {
    try{
        return await founderServices.deleteFounder(params);
    }
    catch(error){
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

const founderSlice = createSlice({
    name: 'founder',
    initialState,
    reducers: {
        setFounderId: (state, action) => {
            state.reviewId = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(addFounder.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(addFounder.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(addFounder.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.message = action.payload;
            })
            .addCase(getFounders.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getFounders.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(getFounders.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.message = action.payload;
                state.founderData = action.payload.data.result;
                state.totalCount = action.payload.data.totalCount;
            })
            .addCase(getFounderById.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getFounderById.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(getFounderById.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.message = action.payload;
                state.founderById = action.payload.data;
            })
            .addCase(deleteFounder.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteFounder.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(deleteFounder.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.message = action.payload;
            })
    }
})

export const { 
    setFounderId,
} = founderSlice.actions;

export default founderSlice.reducer;