import { useState } from "react";
import validator from "validator";
import { toast } from "react-toastify";
import Loader from '../assets/loader.gif';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { addSubAdmin } from "../redux/user/user.slice";

const workData = [
    {
        title: "Dashboard",
        isAssigned: false
    },
    {
        title: "Events",
        isAssigned: false
    },
    {
        title: "Users",
        isAssigned: false
    },
    {
        title: "Orders",
        isAssigned: false
    },
    {
        title: "Gallery",
        isAssigned: false
    },
    {
        title: "Contact Us",
        isAssigned: false
    },
    {
        title: "Create New Event",
        isAssigned: false
    }
]
let formData = {
    name: "",
    email: "",
    password: "",
    assignedWork: workData
}

const AdminForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [subAdminFormData, setSubAdminFormData] = useState(formData);

    const { isLoading } = useSelector((state) => state.user);

    const handleSubmit = () => {
        if(formValidator().isValid){
            const res = dispatch(addSubAdmin(subAdminFormData));
            res?.then((r) => {
                if(r?.payload?.statusCode === 200){
                    setSubAdminFormData({...formData});
                    toast.success(r?.payload?.message);
                    navigate(-1);
                    return;
                }
                toast.error(r?.payload);
            })
            return;
        }
        toast.error(formValidator().message);
    }

    const formValidator = () => {
        let status = {isValid: true, message: ""};
        if(subAdminFormData.name === "" || !subAdminFormData.name){
            status.isValid = false;
            status.message = "Please enter name";
            return status;
        }
        if(subAdminFormData.email === "" || !subAdminFormData.email){
            status.isValid = false;
            status.message = "Please enter email";
            return status;
        }
        if(!validator.isEmail(subAdminFormData.email)){
            status.isValid = false;
            status.message = "Invalid email entered";
            return status;
        }
        if(subAdminFormData.password === "" || !subAdminFormData.password){
            status.isValid = false;
            status.message = "Please enter password";
            return status;
        }
        if(subAdminFormData.password.length < 8){
            status.isValid = false;
            status.message = "Password must be greater than 8 characters";
            return status;
        }
        if(!subAdminFormData.password.match(/[a-z]/g)){
            status.isValid = false;
            status.message = "Password must have atleast a lowercase";
            return status;
        }
        if(!subAdminFormData.password.match(/[A-Z]/g)){
            status.isValid = false;
            status.message = "Password must have atleast an uppercase";
            return status;
        }
        if(!subAdminFormData.password.match(/[0-9]/g)){
            status.isValid = false;
            status.message = "Password must have atleast a number";
            return status;
        }
        if(!subAdminFormData.password.match(/([$&+,:;=?@#|'<>.^*()%!-])/g)){
            status.isValid = false;
            status.message = "Password must have atleast an special character";
            return status;
        }
        return status;
    }

    const handleChange = (e) => {
        e.target.name === "name" && setSubAdminFormData({...subAdminFormData, name: e.target.value});
        e.target.name === "email" && setSubAdminFormData({...subAdminFormData, email: e.target.value});
        e.target.name === "password" && setSubAdminFormData({...subAdminFormData, password: e.target.value});
    }

    return (
        <div className="event-form-container">
            <div className="mt-80 w-50">
                <h4 className="text-center">
                    Create New Sub Admin
                </h4>
                <div>
                    <div className="mb-3 form-box">
                        <label className="form-label">
                            Name
                        </label>
                        <input
                            type="text" 
                            id="name"
                            name="name"
                            value={subAdminFormData.name}
                            onChange={handleChange}
                            className="form-control"
                            placeholder="Enter name"
                        />
                    </div>
                    <div className="mb-3 form-box">
                        <label className="form-label">
                            Email
                        </label>
                        <input
                            type="text" 
                            id="email"
                            name="email"
                            value={subAdminFormData.email}
                            onChange={handleChange}
                            className="form-control"
                            placeholder="Enter email"
                        />
                    </div>
                    <div className="mb-3 form-box">
                        <label className="form-label">
                            Password
                        </label>
                        <input
                            type="password" 
                            id="password"
                            name="password"
                            value={subAdminFormData.password}
                            onChange={handleChange}
                            className="form-control"
                            placeholder="Enter password"
                        />
                    </div>
                    <button
                        type="button"
                        disabled={isLoading}
                        onClick={handleSubmit}
                        className={`btn primary-button-2 ${isLoading && 'btn-disabled'}`}>
                        {isLoading ? <img src={Loader} alt="Loader" /> : "Submit"}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default AdminForm;