import { useSelector } from "react-redux";
import EventList from "../component/EventList";
import AccessDenied from "../component/AccessDenied";
import { isTaskAllowed } from "../utils/isTaskAllowed";

const Home = () => {
    const { admin } = useSelector((state) => state.auth);

    if(admin?.email !== "admin@thriftyx.com" &&
        !isTaskAllowed("Events", admin?.assignedWork)) return (<AccessDenied />)

    return (<EventList />)
}
export default Home;