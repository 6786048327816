import reviewServices from './review.services';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
    totalCount: 0,
    reviewId: null,
    reviewById: null,
    reviewData: null,
};

export const addReview = createAsyncThunk('review/addReview', async(params, thunkAPI) => {
    try{
        return await reviewServices.addReview(params);
    }
    catch(error){
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const getReviews = createAsyncThunk('review/getReviews', async(params, thunkAPI) => {
    try{
        return await reviewServices.getReviews(params);
    }
    catch(error){
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const getReviewById = createAsyncThunk('review/getReviewById', async(params, thunkAPI) => {
    try{
        return await reviewServices.getReviewById(params);
    }
    catch(error){
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const deleteReview = createAsyncThunk('review/deleteReview', async(params, thunkAPI) => {
    try{
        return await reviewServices.deleteReview(params);
    }
    catch(error){
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

const reviewSlice = createSlice({
    name: 'review',
    initialState,
    reducers: {
        setReviewId: (state, action) => {
            state.reviewId = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(addReview.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(addReview.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(addReview.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.message = action.payload;
            })
            .addCase(getReviews.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getReviews.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(getReviews.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.message = action.payload;
                state.reviewData = action.payload.data.result;
                state.totalCount = action.payload.data.totalCount;
            })
            .addCase(getReviewById.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getReviewById.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(getReviewById.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.message = action.payload;
                state.reviewById = action.payload.data;
            })
            .addCase(deleteReview.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteReview.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(deleteReview.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.message = action.payload;
            })
    }
})

export const { 
    setReviewId,
} = reviewSlice.actions;

export default reviewSlice.reducer;