import partnerServices from './partner.services';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
    totalCount: 0,
    partnerById: null,
    partnerData: null,
};

export const addPartner = createAsyncThunk('partner/addPartner', async(params, thunkAPI) => {
    try{
        return await partnerServices.addPartner(params);
    }
    catch(error){
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const getPartners = createAsyncThunk('partner/getPartners', async(params, thunkAPI) => {
    try{
        return await partnerServices.getPartners(params);
    }
    catch(error){
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const getPartnerById = createAsyncThunk('partner/getPartnerById', async(params, thunkAPI) => {
    try{
        return await partnerServices.getPartnerById(params);
    }
    catch(error){
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const deletePartner = createAsyncThunk('partner/deletePartner', async(params, thunkAPI) => {
    try{
        return await partnerServices.deletePartner(params);
    }
    catch(error){
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

const partnerSlice = createSlice({
    name: 'partner',
    initialState,
    reducers: {
        setReviewId: (state, action) => {
            state.reviewId = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(addPartner.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(addPartner.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(addPartner.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.message = action.payload;
            })
            .addCase(getPartners.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getPartners.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(getPartners.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.message = action.payload;
                state.partnerData = action.payload.data.result;
                state.totalCount = action.payload.data.totalCount;
            })
            .addCase(getPartnerById.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getPartnerById.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(getPartnerById.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.message = action.payload;
                state.partnerById = action.payload.data;
            })
            .addCase(deletePartner.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deletePartner.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(deletePartner.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.message = action.payload;
            })
    }
})

export const { 
    setReviewId,
} = partnerSlice.actions;

export default partnerSlice.reducer;