import axios from "axios";
import routes from "../routes";

const addQuestion = async(data) => {
    const res = await axios.post(routes.QUESTION, data, {
        headers: { Authorization: localStorage.getItem("token") },
    });
    return res.data;
}

const getQuestions = async(params) => {
    const { limit, offset, searchText } = params;
    const res = await axios.get(routes.GET_QUESTIONS, {
        params: { limit, offset, searchText },
        headers: { Authorization: localStorage.getItem("token") }
    });
    return res.data;
}

const getQuestionById = async(params) => {
    const { id } = params;
    const res = await axios.get(routes.GET_EVENT+"/"+id, {
        headers: { Authorization: localStorage.getItem("token") }
    });
    return res.data;
}

const deleteQuestion = async(id) => {
    const res = await axios.delete(routes.QUESTION+"/"+id, {
        headers: { Authorization: localStorage.getItem("token") }
    });
    return res.data;
}

const questionServices = { 
    addQuestion,
    getQuestions,
    deleteQuestion,
    getQuestionById
}

export default questionServices;