import { useSelector } from "react-redux";
import Dashboard from "../component/Dashboard";
import AccessDenied from "../component/AccessDenied";
import { isTaskAllowed } from "../utils/isTaskAllowed";

const DashboardView = () => {
    const { admin } = useSelector((state) => state.auth);
    
    if(admin?.email !== "admin@thriftyx.com" &&
        !isTaskAllowed("Dashboard", admin?.assignedWork)) return (<AccessDenied />)

    return (<Dashboard />) 
}
export default DashboardView;