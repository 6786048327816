import Moment from "moment";
import BarChart from './BarChart';
import LineChart from './LineChart';
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { lineChartData } from "../utils/lineChartData";

// const useStyles = makeStyles({
//     searchInput: {
//         marginBottom: 16,
//     },
//     searchIcon: {
//         right: 0,
//         top: 0,
//         padding: "7px 36px",
//         border: "none",
//         borderTopRightRadius: 4,
//         borderBottomRightRadius: 4,
//         backgroundColor: "rgb(108,48,237)"
//     }
// })

const barData = {
    labels: ["a","b","c","d"],
    datasets: [{
      label: 'My First Dataset',
      data: [65, 59, 80, 81],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        
      ],
      borderColor: [
        'rgb(255, 99, 132)',
        'rgb(255, 159, 64)',
        'rgb(255, 205, 86)',
        'rgb(75, 192, 192)',
      ],
      borderWidth: 1
    }]
};

const lineData = {
    labels: ["a","b","c","d"],
    datasets: [{
      label: 'My First Dataset',
      data: [65, 59, 80, 81, 56],
      fill: false,
      borderColor: 'rgb(75, 192, 192)',
      tension: 0.1
    }]
};

const EventStats = (props) => {
    const [graphBarData, setGraphBarData] = useState(barData);
    const [graphLineData, setGraphLineData] = useState(lineData);
    const { eventById } = useSelector((state) => state.event);
    const { orderDataTicket, orderTotalCount } = useSelector((state) => state.user);

    useEffect(() => {
        let newLabel = [];
        let sold = [];
        if(eventById && eventById?.tickets?.length > 0){
            eventById?.tickets?.map((item) => {
                sold.push(item?.sold);
                newLabel.push(item?.name);
                return item;
            })
        }
        setGraphBarData({
            ...graphBarData, 
            labels: newLabel,
            datasets: graphBarData.datasets.map(dataset => ({
                ...dataset,
                data: sold
            }))
        })
    // eslint-disable-next-line
    },[eventById])

    useEffect(() => {
        const { dates, counts } = lineChartData(orderDataTicket)
        setGraphLineData({
            ...graphLineData,
            labels: dates,
            datasets: graphLineData.datasets.map(dataset => ({
                ...dataset,
                data: counts
            }))
        })
    // eslint-disable-next-line
    },[orderDataTicket]);

    return (
        <>
            <div className="container mt-100">
                <div className="row mb-3">
                    <div className="col-md-6 right-border graph-box">
                        <BarChart data={graphBarData} />
                        <h3>Ticket vs Quantity Sold Graph</h3>
                    </div>
                    <div className="col-md-6 graph-box">
                        <LineChart data={graphLineData} />
                        <h3>Date vs Total Ticket Sold Graph</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="order-panel border-primary" style={{paddingTop: 0}}>
                            {/* <div className="position-relative">
                                <button
                                    onClick={searchOrderByTicket}
                                    className={`${classes.searchIcon} position-absolute`}
                                >
                                    <FaSearch color={"#fff"} />
                                </button>
                                <input
                                    type="text"
                                    value={searchValue}
                                    onChange={handleSearchText}
                                    className={`${classes.searchInput} form-control`} 
                                    placeholder="Search order" 
                                />
                            </div> */}
                            {/* table-mt -> for margin top in below table if required */}
                            <div className="table-responsive order-table">
                                <table className="table table-borderless">
                                    <thead className="table-dark">
                                        <tr>
                                            <th>
                                                Order Id
                                            </th>
                                            <th>
                                                Buyer Info
                                            </th>
                                            <th>
                                                Ticket Name
                                            </th>
                                            <th>
                                                Purchased On
                                            </th>
                                            <th>
                                                Qty
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="ticket-content">
                                        {(orderDataTicket && orderDataTicket?.length > 0) ? 
                                            orderDataTicket?.map((item, index) => (
                                                item?.tickets?.length > 0 && 
                                                item?.tickets?.map((it, i) => (
                                                    <tr key={`${index}${i}`}>
                                                        <td>{item?.order_id?.split("_")[1]}</td>
                                                        <td>
                                                            <b>{item?.user_id?.name}</b><br/>
                                                            {item?.user_id?.email}<br/>
                                                            {item?.user_id?.phone}
                                                        </td>
                                                        <td>{it?.name}</td>
                                                        <td>{Moment(item?.updatedBy).format("MMM DD YYYY")}</td>
                                                        <td>{it?.count}</td>
                                                    </tr>
                                                ))
                                            )) :
                                            <tr>
                                                <td colSpan="5" className="text-center">
                                                    No Ticket Purchased
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                                {/* {orderTotalCount > 10 &&
                                    <nav aria-label="Page navigation example">
                                        <ul className="pagination justify-content-between">
                                            <li className={`page-item ${offset === 0 && 'disabled'}`}>
                                                <button
                                                    className="page-link"
                                                    aria-disabled="true"
                                                    onClick={() => pageChange("prev")}>
                                                    Previous
                                                </button>
                                            </li>
                                            <li className={`page-item ${orderDataTicket?.length < 10 && 'disabled'}`}>
                                                <button
                                                    className="page-link"
                                                    onClick={() => pageChange("next")}>
                                                    Next
                                                </button>
                                            </li>
                                        </ul>
                                    </nav>
                                } */}
                            </div>
                            {orderTotalCount > 10 &&
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination justify-content-between">
                                        <li className={`page-item ${props.offset === 0 && 'disabled'}`}>
                                            <button
                                                className="page-link"
                                                aria-disabled="true"
                                                onClick={() => props.pageChange("prev")}>
                                                Previous
                                            </button>
                                        </li>
                                        <li className={`page-item ${orderDataTicket?.length < 10 && 'disabled'}`}>
                                            <button
                                                className="page-link"
                                                onClick={() => props.pageChange("next")}>
                                                Next
                                            </button>
                                        </li>
                                    </ul>
                                </nav>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default EventStats;